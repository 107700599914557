import * as React from 'react';
import Layout from '../components/Layout';
import AboutView from '../modules/about';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="description" content="We are continuing to scale and some thoughts and peeks about the company" />
        <title>People behind our successful journey - Zegates International</title>
      </Helmet>
      <AboutView />
      <Footer />
    </Layout>
  );
};

export default About;
